import React, { useState } from 'react';
import { InputSso, TitleFont20WithoutMarginBlueSso, ButtonSsoHover, IconOnaos, TextCenterBlue } from 'onaosreact'
import { getAPI } from '../../api/api';

const TAB = [
    {
        title: "E-mail",
        placeholder: "Entrer votre mail",
        onChange: "",
        id: "email",
        errors: {
            is: false,
            msg: "Veuillez entrer votre email"
        },
    },
]

export const ForgetPassword = (props) => {

    const [datas, setDatas] = useState({ email: "" });
    const [, updateState] = useState();
    const [showMsg, setMsg] = useState(false)

    const saveName = (id, value) => {
        const tmp = { ...datas }
        tmp[id] = value
        setDatas(tmp)
    }

    const divStyle = {
        padding: "0 10px",
        display: 'flex',
        alignItems: "center",
        flexFlow: "column wrap",
    }

    const divPosition = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
    }

    const checkDatas = () => {

        let isError = false;

        for (let index = 0; index < TAB.length; index++) {
            TAB[index].errors.is = datas[TAB[index].id] === "" ? true : false

            if (TAB[index].errors.is === true) {
                isError = true
            }

            isError = !isError && TAB[index].errors.is ? true : isError

            if (isError)
                updateState({})
            else {
                getAPI("/forgetpassword/"+datas.email,"").then(data => {
                    if (data.ok) {
                        setMsg(true)
                    } else {
                        updateState({})
                    }
                }
                    
                )
            }
        }
    }

    return (
        <div style = {{ 
            margin:'auto',
            display:'flex',
            justifyContent:'center'
            }}
        >
            <div style={divStyle}>
                <IconOnaos style={{ margin: "-35px" }} />
                <div style={divPosition}>
                    <TitleFont20WithoutMarginBlueSso
                        text="Mot de passe oublié"
                    />
                    {TAB.map((data, k) =>
                        <InputSso
                            key={"inputssso_" + k}
                            title={data.title}
                            password={data.password}
                            placeholder={data.placeholder !== undefined ? data.placeholder : datas[data.id]}
                            onChange={data.onChange !== undefined ? (e) => saveName(data.id, e) : ""}
                            data={data}
                            errors={data.errors}
                        />
                    )}
                    <div style={{ marginTop: "15px" }}>
                        <ButtonSsoHover
                            text="Envoyer le mail de récupération"
                            onClick={() => checkDatas()}
                        />
                    </div>
                    {showMsg &&
                        <div>
                            <TextCenterBlue 
                                text = "L'email de récupération a été envoyé"
                                style = {{color  :'rgb(103, 153, 30)', fontWeight : 'bold'}}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}