import React from 'react';
import { TitleFont20WithoutMarginBlueSso, UnderTextSso, IconOnaos, TextNoStyle, IconFailed } from 'onaosreact'
import { internalDiv } from '../styles/internalDiv';


export const InvalidLink = () => {

    const divAlign = {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '431px',
        justifyContent: "space-around",
    }

    return (
        <div style={internalDiv.mainContainerStyle}>
            <div style={divAlign}>
                <IconOnaos style={{ margin: "-35px" }} />
                <IconFailed style={{ width: "350px"}}/>
                <TitleFont20WithoutMarginBlueSso
                    text="Lien invalide"
                />
                <TextNoStyle
                    text="Merci de contacter le service client si nécessaire"
                    style={{ fontSize: "12px" }}
                />
            </div>
            <footer>
                <UnderTextSso
                    undertext="Pour revenir à la page d'accueil, cliquer ici"
                />
            </footer>
        </div>
    )
}
