export const internalDiv = {
    mainContainerStyle :  {
        marginLeft:'auto',
        marginRight:'auto',
        width:'95%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        height:'98%',
    },

    containerStyle : {
        marginTop:'1%',
        marginBottom:'1%',

        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        height:'98%',

    }
}