import React, {useEffect} from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import './App.css';
import { CreateAccountSuccess } from './createAccount/CreateAccountSucces';
import { Connexion } from './identification/connexion/Connexion';

import { ForgetPassword } from './identification/forgetPassword/ForgetPassword';
import { Redirection, RedirectionPassword } from './redirection/Redirection';




export const App = () => {

  const search = window.location.search
  const params = new URLSearchParams(search)
  const r = params.get('r') === null ? "dashboard" : params.get('r');

  const id = params.get('id');
  const change = params.get('recup')

  useEffect(() => {


    // 👇️ set style on body element
    document.body.style.backgroundColor = '#f7f7f7';

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);


  return (
    <div className="App" style = {{display:'flex', marginTop : '-50px', justifyContent: 'center'}}>
      <Router>
        <Routes>
          {change !== null &&
            <Route exact path="*" element={<RedirectionPassword change={change} />} />
          }
          {id === null &&
            <Route path="*" element={<Connexion service={r} />} />
          }
          {id !== null &&
            <Route exact path="*" element={<Redirection id={id} />} /> //create account
          }

          <Route exact path="/oubli" element={<ForgetPassword />} />
          <Route exact path="/createaccountsucces" element={<CreateAccountSuccess />} />
        </Routes>
      </Router>

    </div>
  );
}


