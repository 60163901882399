
import axios from 'axios';

const appConfig = process.env.REACT_APP_API_ENDPOINT

  export const apiCPS = async(url,data)=>{
    const cps = await axios.put(appConfig+url,data)
    return cps.data

  }


  export const apiRedirection = async(url)=>{
    const cps = await axios.get(appConfig+url)
    return cps.data

  }

   export const apiLogin = async (user,pass,service) => {
     let error= []

     const response = await axios.get(appConfig+'/identification',{
       headers: {
         user: user,
         pass:  pass,
         service: service,
       }
     }).catch( e => {
       error = {
         status: e.response.status,
         message: "error login"
       }
     })
     if (error.status=== undefined && response.status===200) {
        const dd = {
          ...response.data,
          status : response.status
        }
       return dd
     } else {
       return error
     }
  }



   const refresh = async (tokenrefresh) => {
    const token = await axios.get(appConfig+'/tokenrefresh/'+tokenrefresh)
    localStorage.setItem('token',token.data.token)
    return token.data.token
  }



  const reGet = async (url,token,reptype=null) => {
   let error = []
   const data =  await axios.get(url,{
     headers: {
       token: token
     },
     responseType: reptype
   }).catch( e => {
     error = {
       status: e.response.status,
       message: "get error"
     }
   })
   if (data.status===200) {
     return data
   } else {
     return error
   }
 }





  export const getAPI = async (index,tokenrefresh, reptype=null) => {

    const url = appConfig + index
    let error= []
    let response = await axios.get(url,{
      headers: {
        token: localStorage.getItem('token')
      },
      responseType: reptype
    }).catch( e => {
      error = {
        status: e.response.status,
        message: "get error"
      }
    })

    if (error.status!== undefined && error.status === 401 ) {
        error.status=undefined
        response = await refresh(tokenrefresh).then( tok => reGet(url,tok,reptype) )
    }

    if (error.status=== undefined && response.status===200) {
      return response.data
    } else {
      if (error!==undefined) {
        return error
      } else {
        return response
      }
    }
  }


  const rePut = async (index,token,datas,reptype=null) => {
   let error = []
   const url = appConfig + index

   const data =  await axios.put(url,datas,{
     headers: {
       token: token
     },
     responseType: reptype
   }).catch( e => {
     error = {
       status: e.response.status,
       message: "get error"
     }
   })

   if (data.status===200) {
     return data
   } else {
     return error
   }

 }

 const rePost = async (index,token,datas,reptype=null) => {
  let error = []
  const url = appConfig + index

  const data =  await axios.post(url,datas,{
    headers: {
      token: token
    },
    responseType : reptype
  }).catch( e => {
    error = {
      status: e.response.status,
      message: "get error"
    }
  })

  if (data.status===200) {
    return data
  } else {
    return error
  }

}


 export const postAPI = async (index,tokenrefresh,data,reptype=null) => {
   const url = appConfig + index
   let error= []
   let response = await axios.post(url,data,{
     headers: {
       token: localStorage.getItem('token'),
       Accept: 'application/json',
       'Content-Type': 'multipart/form-data',
     },
     responseType : reptype
   }).catch( e => {
     error = {
       status: e.response.status,
       message: "get error"
     }
   })

   if (error.status!== undefined && error.status === 401 ) {
       error.status=undefined
       response = await refresh(tokenrefresh).then( tok => rePost(url,tok,data) )
   }

   if (error.status=== undefined && response.status===200) {
     return response.data
   } else {
     if (error!==undefined) {
       return error
     } else {
       return response
     }

   }
 }



  const rePatch = async (index,token,datas) => {
   let error = []
   const url = appConfig + index

   const data =  await axios.patch(url,datas,{
     headers: {
       token: token
     },
   }).catch( e => {
     error = {
       status: e.response.status,
       message: "get error"
     }
   })

   if (data.status===200) {
     return data
   } else {
     return error
   }

 }

  export const patchAPI = async (index,tokenrefresh,data) => {
    const url = appConfig + index
    let error= []
    let response = await axios.patch(url,data,{
      headers: {
        token: localStorage.getItem('token'),
      },
    }).catch( e => {
      error = {
        status: e.response.status,
        message: "get error"
      }
    })

    if (error.status!== undefined && error.status === 401 ) {
        error.status=undefined
        response = await refresh(tokenrefresh).then( tok => rePatch(url,tok,data) )
    }

    if (error.status=== undefined && response.status===200) {
      return response.data
    } else {
      if (error!==undefined) {
        return error
      } else {
        return response
      }

    }
  }


  export const putAPI = async (index,tokenrefresh,data,reptype=null) => {
    const url = appConfig + index
    let error= []
    let response = await axios.put(url,data,{
      headers: {
        token: localStorage.getItem('token')
      },
      responseType: reptype
    }).catch( e => {
      error = {
        status: e.response.status,
        message: "get error"
      }
    })

    if (error.status!== undefined && error.status === 401 ) {
        error.status=undefined
        response = await refresh(tokenrefresh).then( tok => rePut(url,tok,data) )
    }

    if (error.status=== undefined && response.status===200) {
      return response.data
    } else {
      if (error!==undefined) {
        return error
      } else {
        return response
      }

    }
  }
