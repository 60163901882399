import { createSlice } from "@reduxjs/toolkit";



export const tokenSlice = createSlice({
    name: "token",
    initialState: {
        id: "",
        email: "",
        valid: undefined,

    },
    reducers: {
        setToken: (state, action) => {
            state.email = action.payload.email;
            state.id = action.payload.token;
            state.valid = action.payload.valid;

            return state
        },
    }
})


export const { setToken } = tokenSlice.actions;